@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@400;900&display=swap');
@import "~react-vis/dist/style";

html {
  scroll-behavior: smooth;
}

body {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0px;
}

code {
  font-family: 'Source Code Pro', monospace;
  font-weight: 900;
  color: rgb(55, 65, 81);
}

.spin-twice {
  -webkit-animation:spin 1s ease-in-out 0s 2;
  -moz-animation:spin 1s ease-in-out 0s 2;
  animation:spin 1s ease-in-out 0s 2;
}

.infinite {
  -webkit-animation:spin 1s ease-in-out 0s infinite;
  -moz-animation:spin 1s ease-in-out 0s infinite;
  animation:spin 1s ease-in-out 0s infinite;
}


@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }
